<template>
  <div class="px-5" id="page-support">
    <h5 class="mb-6 ml-2">Systeminställningar</h5>

    <div class="card card-custom card-stretch gutter-b">
      <!--begin::Header-->
      <div class="card-header border-0 py-5">
        <h3 class="card-title align-items-start flex-column">
          <span class="card-label font-weight-bolder text-dark"></span>
        </h3>
        <div class="card-toolbar"></div>
      </div>
      <!--end::Header-->
      <!--begin::Body-->
      <div class="card-body pt-0 pb-3">
        
        <!--begin::Table-->
        <div class="table-responsive">
          <table
            class="table table-head-custom table-vertical-center table-head-bg table-borderless"
          >
            <tbody>
              <tr style="background-color: black; color: white;">
                <td>Föreningar</td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>Systemförening</td>
                <td>
                  <b-form-select
                    v-model="system_company_id"
                    :options="parent_companies"
                  ></b-form-select>
                </td>
                <td></td>
              </tr>
              <tr>
                <td>Riksförbund mall</td>
                <td>
                  <b-form-select
                    v-model="riks_company_id"
                    :options="parent_companies"
                  ></b-form-select>
                </td>
                <td></td>
              </tr>
              <tr>
                <td>Studentkår mall</td>
                <td>
                  <b-form-select
                    v-model="student_company_id"
                    :options="parent_companies"
                  ></b-form-select>
                </td>
                <td></td>
              </tr>
              <tr>
                <td>Förening mall</td>
                <td>
                  <b-form-select
                    v-model="default_company_id"
                    :options="parent_companies"
                  ></b-form-select>
                </td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
        <!--end::Table-->
      </div>
      <!--end::Body-->
    </div>

    <h4 class="mb-8">Systemmallar</h4>
    <SystemTable
      title="Användarmallar"
      :items.sync="userTemplates"
      :templateOptions="templateOptions"
      @templateUpdated="templateUpdated"
    />

    <SystemTable
      title="Medlemsmallar"
      :items.sync="memberTemplates"
      :templateOptions="templateOptions"
      @templateUpdated="templateUpdated"
    />

    <SystemTable
      title="Ärendemallar"
      :items.sync="todoTemplates"
      :templateOptions="templateOptions"
      @templateUpdated="templateUpdated"
    />

    <Titles
      v-if="system"
      :titles="titles"
      @updateTitle="updateTitle"
      @createTitle="createTitle"
      @deleteTitle="deleteTitle"
    />

    <MemberTypes
      v-if="system"
      :membertypes="membertypes"
      @updateMemberType="updateMemberType"
      @createMemberType="createMemberType"
      @deleteMemberType="deleteMemberType"
    />

    <b-button type="button" variant="primary" @click="saveSystem">Spara</b-button>
  </div>
</template>

<style lang="scss" scoped>
@import "@/assets/sass/components/forms/_memlist_checkbox.scss";
</style>


<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import SystemTable from '@/view/pages/ml/system/SystemTable.vue';
import Titles from '@/view/pages/ml/system/Titles.vue';
import MemberTypes from '@/view/pages/ml/system/MemberTypes.vue';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

export default {
  name: 'system',
  components: {
    SystemTable,
    Titles,
    MemberTypes
  },
  
  mixins: [ toasts ],

  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId'])
  },
  mounted() {
    this.getAllTitles();
    this.getAllMemberTypes();
    this.getTopCompanies();
    this.loadData();
    this.loadTemplates();
  },
  data() {
    return {
      titles: [],
      membertypes: [],
      system_company_id: null,
      riks_company_id: null,
      default_company_id: null,
      student_company_id: null,
      parent_companies: [],
      system: null,
      templateOptions: [],
      templates: [],
      userTemplates: [],
      memberTemplates: [],
      todoTemplates: []
    };
  },
  watch: {
    currentCompanyId(newValue, oldvalue) {
      if (newValue) {
        this.loadTemplates();
      }
    }
  },
  methods: {
    getTopCompanies() {
      axios
        .get('/company/listtop')
        .then(res => {
          const parents = res.data;

          parents.sort(function(a, b) {
            if (a.name < b.name) {
              return -1;
            }

            return 1;
          });

          this.parent_companies = [];

          for (const p of parents) {
            this.parent_companies.push({ value: p.company_id, text: p.name });
          }
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte lista föreningar');
        });
    },
    getAllMemberTypes() {
      axios
        .get(`/membertype`)
        .then(res => {
          if (res.status === 200) {
            this.membertypes = res.data;
          } else {
            this.toastr('danger', this.$t('COMMON.ERROR'), 'Hittade inga medlemstyper');
          }
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte lista medlemstyper');
        });
    },
    getAllTitles() {
      axios
        .get(`/title`)
        .then(res => {
          if (res.status === 200) {
            this.titles = res.data;
          } else {
            this.toastr('danger', this.$t('COMMON.ERROR'), 'Hittade inga titlar');
          }
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte lista titlar');
        });
    },
    deleteTitle(id) {
      axios
        .delete(`/title/${id}`)
        .then(res => {
          this.titles = this.titles.filter(item => item.id !== id);

          this.toastr('success', this.$t('COMMON.OK'), 'Systemtiteln togs bort');
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte ta bort titel');
        });
    },
    updateTitle(id, name, level) {
      axios
        .put(`/title/${id}`, { id: id, name: name, level: level })
        .then(res => {
          for (const t of this.titles) {
            if (t.id === id) {
              t.name = name;
              t.level = level;
              break;
            }
          }

          this.toastr('success', this.$t('COMMON.OK'), 'Titel uppdaterad');
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte uppdatera titel');
        });
    },
    createTitle(name, level) {
      axios
        .post('/title', { name: name, level: level })
        .then(res => {
          this.titles.push(res.data);

          this.toastr('success', this.$t('COMMON.OK'), 'Titel skapad');
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte skapa titel');
        });
    },

    // -- membertypes
    deleteMemberType(id) {
      axios
        .delete(`/membertype/${id}`)
        .then(res => {
          this.membertypes = this.membertypes.filter(item => item.id !== id);

          this.toastr('success', this.$t('COMMON.OK'), 'Medlemstypen togs bort');
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte ta bort medlemstyp');
        });
    },
    updateMemberType(id, name) {
      axios
        .put(`/membertype/${id}`, { name: name })
        .then(res => {
          for (const t of this.membertypes) {
            if (t.id === id) {
              t.name = name;
              break;
            }
          }

          this.toastr('success', this.$t('COMMON.OK'), 'Medlemstypen uppdaterad');
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte uppdatera medlemstyp');
        });
    },
    createMemberType(name) {
      axios
        .post('/membertype', { name: name })
        .then(res => {
          this.membertypes.push(res.data);

          this.toastr('success', this.$t('COMMON.OK'), 'Medlemstypen skapad');
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte skapa medlemstyp');
        });
    },

    // -- end membertypes

    templateUpdated(id, template_id) {

      for (var i = 0; i < this.memberTemplates.length; ++i) {
        if (this.memberTemplates[i].id === id) {
          this.memberTemplates[i].template_id = template_id;
        }
      }
      for (var i = 0; i < this.userTemplates.length; ++i) {
        if (this.userTemplates[i].id === id) {
          this.userTemplates[i].template_id = template_id;
        }
      }
      for (var i = 0; i < this.todoTemplates.length; ++i) {
        if (this.todoTemplates[i].id === id) {
          this.todoTemplates[i].template_id = template_id;
        }
      }
    },
    saveSystem() {
      var data = {
        system_company_id: this.system_company_id,
        riks_company_id: this.riks_company_id,
        default_company_id: this.default_company_id,
        student_company_id: this.student_company_id
      };

      for (var i = 0; i < this.memberTemplates.length; ++i) {
        data[this.memberTemplates[i].id] = this.memberTemplates[i].template_id;
      }
      for (var i = 0; i < this.userTemplates.length; ++i) {
        data[this.userTemplates[i].id] = this.userTemplates[i].template_id;
      }
      for (var i = 0; i < this.todoTemplates.length; ++i) {
        data[this.todoTemplates[i].id] = this.todoTemplates[i].template_id;
      }

      axios
        .put('/system', data)
        .then(res => {
          this.toastr('success', this.$t('COMMON.OK'), 'Systemet uppdaterades');
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte uppdatera system');
        });
    },
    loadData() {
      axios
        .get(`/system`)
        .then(res => {
          var sys = res.data;

          this.system = res.data;
          this.system_company_id = this.system.system_company_id;
          this.default_company_id = this.system.default_company_id;
          this.riks_company_id = this.system.riks_company_id;
          this.student_company_id = this.system.student_company_id;

          this.userTemplates = [
            { id: 'forget_password_template_id', descr: this.$t('MEMBER.FORGET_PASSWORD'), template_id: '' },
            { id: 'invite_by_email_template_id', descr: this.$t('SYSTEM.TEMPLATE.INVITE_BY_EMAIL_TEMPLATE'), template_id: '' },
            {
              id: 'confirm_admin_email_template_id',
              descr: 'Bekräfta admin e-post',
              template_id: ''
            },
            { id: 'contact_form_template_id', descr: this.$t('SYSTEM.TEMPLATE.CONTACT_FORM'), template_id: '' },
            {
              id: 'subcompany_request_template_id',
              descr: this.$t('SYSTEM.TEMPLATE.SUBCOMPANY_REQUEST'),
              template_id: ''
            },
            {
              id: 'subcompany_accept_template_id',
              descr: this.$t('SYSTEM.TEMPLATE.SUBCOMPANY_ACCEPT'),
              template_id: ''
            },
            {
              id: 'subcompany_reject_template_id',
              descr: this.$t('SYSTEM.TEMPLATE.SUBCOMPANY_REJECT'),
              template_id: ''
            },
            {
              id: 'parent_company_request_template_id',
              descr: this.$t('SYSTEM.TEMPLATE.PARENT_COMPANY_REQUEST'),
              template_id: ''
            },
            {
              id: 'grant_finalized_template_id',
              descr: this.$t('SYSTEM.TEMPLATE.GRANT_FINALIZED'),
              template_id: ''
            },
            {
              id: 'grant_must_handin_template_id',
              descr: this.$t('SYSTEM.TEMPLATE.GRANT_MUST_HANDIN'),
              template_id: ''
            },
            {
              id: 'grant_reject_template_id',
              descr: this.$t('SYSTEM.TEMPLATE.GRANT_REJECT'),
              template_id: ''
            },
            {
              id: 'grant_accept_template_id',
              descr: this.$t('SYSTEM.TEMPLATE.GRANT_ACCEPT'),
              template_id: ''
            },
            {
              id: 'grant_created_template_id',
              descr: this.$t('SYSTEM.TEMPLATE.GRANT_CREATED'),
              template_id: ''
            },
            {
              id: 'grant_has_handin_template_id',
              descr: this.$t('SYSTEM.TEMPLATE.GRANT_HAS_HANDIN'),
              template_id: ''
            },
            {
              id: 'grant_deleted_template_id',
              descr: this.$t('SYSTEM.TEMPLATE.GRANT_DELETED'),
              template_id: ''
            },
            {
              id: 'event_joined_template_id',
              descr: this.$t('SYSTEM.TEMPLATE.EVENT_JOINED'),
              template_id: ''
            },
            {
              id: 'instance_return_template_id',
              descr: this.$t('PAGES.SYSTEM.INSTANCE_RETURN_TEMPLATE_ID'),
              template_id: ''
            }, 
            {
              id: 'instance_timeout_template_id',
              descr: this.$t('PAGES.SYSTEM.INSTANCE_TIMEOUT_TEMPLATE_ID'),
              template_id: ''
            },
            {
              id: 'admin_inquiry_template_id',
              descr: this.$t('PAGES.SYSTEM.ADMIN_INQUIRY_TEMPLATE_ID'),
              template_id: ''
            },
            {
              id: 'customer_confirm_inquiry_template_id',
              descr: this.$t('PAGES.SYSTEM.CUSTOMER_CONFIRM_INQUIRY_TEMPLATE_ID'),
              template_id: ''
            }
            
          ];

          this.memberTemplates = [
            { id: 'stickprov_sms_template_id', descr: this.$t('SYSTEM.TEMPLATE.STICKPROV_SMS'), template_id: '' },
            { id: 'stickprov_email_template_id', descr: this.$t('SYSTEM.TEMPLATE.STICKPROV_EMAIL'), template_id: '' },
            {
              id: 'forget_password_members_template_id',
              descr: this.$t('MEMBER.FORGET_PASSWORD'),
              template_id: ''
            },
            { id: 'member_joined_template_id', descr: this.$t('SYSTEM.TEMPLATE.MEMBER_JOINED'), template_id: '' },
            {
              id: 'member_renew_period_sms_template_id',
              descr: this.$t('SYSTEM.TEMPLATE.MEMBER_RENEW_PERIOD_SMS'),
              template_id: ''
            },
            {
              id: 'member_renew_remind_sms_template_id',
              descr: this.$t('SYSTEM.TEMPLATE.MEMBER_RENEW_REMIND_SMS'),
              template_id: ''
            },
            {
              id: 'member_renew_confirm_sms_template_id',
              descr: this.$t('SYSTEM.TEMPLATE.MEMBER_RENEW_CONFIRM_SMS'),
              template_id: ''
            },
            {
              id: 'member_renew_period_email_template_id',
              descr: this.$t('SYSTEM.TEMPLATE.MEMBER_RENEW_PERIOD_EMAIL'),
              template_id: ''
            },
            {
              id: 'member_renew_remind_email_template_id',
              descr: this.$t('SYSTEM.TEMPLATE.MEMBER_RENEW_REMIND_EMAIL'),
              template_id: ''
            },
            {
              id: 'member_renew_confirm_email_template_id',
              descr: this.$t('SYSTEM.TEMPLATE.MEMBER_RENEW_CONFIRM_EMAIL'),
              template_id: ''
            },
            {
              id: 'payment_reminder_template_id',
              descr: this.$t('SYSTEM.TEMPLATE.PAYMENT_REMINDER'),
              template_id: ''
            },
            {
              id: 'payment_recieved_template_id',
              descr: this.$t('SYSTEM.TEMPLATE.PAYMENT_RECIEVED'),
              template_id: ''
            },
            {
              id: 'payment_fail_template_id',
              descr: this.$t('SYSTEM.TEMPLATE.PAYMENT_FAIL'),
              template_id: ''
            },
            {
              id: 'invite_nonmember_from_event_template_id',
              descr: this.$t('SYSTEM.TEMPLATE.INVITE_NONMEMBER_FROM_EVENT'),
              template_id: ''
            },
            {
              id: 'redeem_code_email_template_id',
              descr: this.$t('SYSTEM.TEMPLATE.REDEEM_CODE_EMAIL'),
              template_id: ''
            },
            {
              id: 'redeem_code_sms_template_id',
              descr: 'Kupong med kod (SMS)',
              template_id: ''
            },
            {
              id: 'member_application_pending_template_id',
              descr:  this.$t('SYSTEM.TEMPLATE.MEMBER_APPLICATION_PENDING_TEMPLATE_ID'),
              template_id: ''
            },
            {
              id: 'member_approved_email_template_id',
              descr: this.$t('SYSTEM.TEMPLATE.MEMBER_APPROVED_EMAIL_TEMPLATE_ID'),
              template_id: ''
            },
            {
              id: 'member_rejected_email_template_id',
              descr: this.$t('SYSTEM.TEMPLATE.MEMBER_REJECTED_EMAIL_TEMPLATE_ID'),
              template_id: ''
            },
          ];

          this.todoTemplates = [
            { id: 'todo_new_template_id', descr: 'Nytt ärende', template_id: '' },
            { id: 'todo_remind_template_id', descr: 'Påminnelse om ärende', template_id: '' },
            {
              id: 'todo_creator_update_template_id',
              descr: 'Ärende uppdaterat (Ägare)',
              template_id: ''
            },
            {
              id: 'todo_company_update_template_id',
              descr: 'Ärende uppdaterat (Förening)',
              template_id: ''
            },
            {
              id: 'helpdesk_creator_template_id',
              descr: 'Helpdesk ärende uppdaterat (Till Skapare)',
              template_id: ''
            },
            {
              id: 'helpdesk_system_template_id',
              descr: 'Helpdesk ärende uppdaterat (Till ThinkingHat)',
              template_id: ''
            }
          ];

          for (var i = 0; i < this.userTemplates.length; ++i) {
            this.userTemplates[i].template_id = sys[this.userTemplates[i].id];
          }
          for (var i = 0; i < this.memberTemplates.length; ++i) {
            this.memberTemplates[i].template_id = sys[this.memberTemplates[i].id];
          }
          for (var i = 0; i < this.todoTemplates.length; ++i) {
            this.todoTemplates[i].template_id = sys[this.todoTemplates[i].id];
          }
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte hämta systeminställningar');
        });
    },

    loadTemplates() {
      this.templates = [];

      axios
        .get(`/template?company_id=${this.currentCompanyId}`)
        .then(res => {
          if (res.status === 200) {
            this.templates = res.data;

            this.templateOptions.push({
              value: null,
              text: 'Ingen'
            });

            for (var i = 0; i < this.templates.length; ++i) {
              this.templateOptions.push({
                value: this.templates[i].template_id,
                text: this.templates[i].name
              });
            }
          }
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('TEMPLATE.UNABLE_LIST'));
        });
    }
    
  }
};
</script>
